import { createRouter, createWebHistory } from 'vue-router'
// import index from '@/views/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/details/aboutUs.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/details/product.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/home/brand.vue')
  },

  {
    path: '/productkemp',
    name: 'productkemp',
    component: () => import('@/views/home/productkemp.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
